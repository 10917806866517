
import { mapWritableState } from 'pinia'
import { useUiStore } from '@/stores/ui'

export default {
  name: 'PopupRegistrationRequired',
  computed: {
    ...mapWritableState(useUiStore, [
      'registrationRequired',
      'weReceivedYourRequest',
    ]),
  },
  beforeDestroy() {
    this.registrationRequired = false
    this.weReceivedYourRequest = false
  },
  methods: {
    closeModal() {
      this.registrationRequired = false
    },
    closeModalResive() {
      this.weReceivedYourRequest = false
    },
    toLogin() {
      this.$router.push({
        path: this.localePath('auth-authentication'),
      })
    },
  },
}
